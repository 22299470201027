@font-face {
  font-family: 'Gilroy';
  src: url('../../fonts/gilroy-regular.eot');
  src: url('../../fonts/gilroy-regular.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/gilroy-regular.woff2') format('woff2'),
    url('../../fonts/gilroy-regular.woff') format('woff'),
    url('../../fonts/gilroy-regular.ttf') format('truetype'),
    url('../../fonts/gilroy-regular.svg#gilroy') format('svg');
}

.terms-wrapper {
  display: flex;
  justify-content: center;
  font-family: 'Gilroy';
  padding: 40px;
}
