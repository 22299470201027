@font-face {
  font-family: 'Roboto';
  src: url('../../fonts/roboto.eot');
  src: url('../../fonts/roboto.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/roboto.woff2') format('woff2'), url('../../fonts/roboto.woff') format('woff'),
    url('../../fonts/roboto.ttf') format('truetype'),
    url('../../fonts/roboto.svg#roboto') format('svg');
}

@font-face {
  font-family: 'Roboto-Medium';
  src: url('../../fonts/roboto-medium.eot');
  src: url('../../fonts/roboto-medium.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/roboto-medium.woff2') format('woff2'),
    url('../../fonts/roboto-medium.woff') format('woff'),
    url('../../fonts/roboto-medium.ttf') format('truetype'),
    url('../../fonts/roboto-medium.svg#roboto-medium') format('svg');
}

.image {
  overflow: hidden;
  max-height: 400px;
}
.image img {
  width: 100%;
  height: 100%;
}

/*.trends-wrapper {*/
/*  display: flex;*/
/*  flex-direction: column;*/
/*  justify-content: center;*/
/*  align-items: center;*/
/*}*/

.trends-wrapper .content {
  max-width: 1140px;
  margin: 0 auto;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.trends-column {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.trends-column > * {
  flex: 0 0 30%;
}

.page-title {
  text-align: center;
  margin: 80px 0 40px 10px;
}

.trending {
  font-family: 'Roboto', sans-serif;
  font-size: 42px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;
  letter-spacing: normal;
  color: #373737;
  margin: 0;
  z-index: 1;
}

.sub-title {
  font-size: 28px;
  font-weight: 400;
}

.trends-column .item {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 550px;
  height: auto;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.19);
  background-color: #ffffff;
  margin: 18px 10px;
  text-decoration: none;
}

.item .title {
  font-family: 'Roboto-Medium', sans-serif;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  color: #373737;
  margin: 0;
}

.title_desc {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  margin: 0;
  padding: 0 24px;
}

.item .desc {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.16px;
  color: #373737;
  margin: 0;
}

.view_upload {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  font-family: 'Roboto', sans-serif;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: 0.02px;
  color: #373737;
  padding: 0 24px;
}

.item .views-icon {
  height: 16px;
  width: 16px;
  margin: 0;
  padding: 0 12px 0 0;
}

.item .views {
  font-size: 14px;
  text-align: left;
  display: flex;
  justify-content: center;
}

.item .upload {
  width: 50%;
  font-size: 14px;
  text-align: right;
}

.item .tags {
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: #5c5c5c;
  margin: 0;
}

.name_tags {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding: 20px 24px 12px 24px;
  margin-top: auto;
  margin-bottom: 5px;
}

.channel-name {
  width: 50%;
  color: #373737;
  font-family: 'Roboto-Medium', sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  margin: 0;
  display: flex;
  align-items: center;
}

.item .done-icon {
  width: 16px;
  height: 16px;
  padding: 0 0 0 8px;
}

.video-container {
  position: relative;
}

.duration-block {
  border-radius: 2px;
  border: #222222;
  background-color: #ffffff;
  position: absolute;
  bottom: 20px;
  right: 20px;
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.02px;
  color: #373737;
  text-align: center;
  padding: 4px 10px;
}

.category-block {
  border-radius: 2px;
  background-color: #ffffff;
  position: absolute;
  top: 8px;
  right: 20px;
  font-family: 'Roboto-Medium', sans-serif;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.02px;
  color: #373737;
  text-align: center;
  padding: 4px 10px;
}

.video-item {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  flex: 1;
}

.thats-all {
  display: flex;
  white-space: nowrap;
  justify-content: center;
  align-items: center;
  font-family: 'Roboto-Medium', sans-serif;
  font-size: 42px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.02px;
  color: #373737;
  text-align: center;
}

.thats-all img {
  height: 42px;
  width: auto;
  margin: 0 2rem;
}

.menu-item-wrapper {
  outline: none;
}

.menu-item {
  padding: 7px 20px;
  margin: 5px 10px;
  border-radius: 16px;
  border: solid 2px #DAB84B;
  color: #DAB84B;
  outline: none;
  font-weight: 600;
}

.menu-item.active {
  background-color: #DAB84B;
  outline: none;
  color: #fff
}

@media (max-width: 950px) {
  .trends-wrapper .content {
    width: 90%;
  }

  .page-title {
    margin: 2rem 0;
  }

  .trending {
    font-size: 18px;
  }

  .sub-title {
    display: none;
  }

  .trends-column .item {
    min-width: 100%;
  }

  .item .tags {
    padding: 0;
  }

  .name_tags {
    padding: 1rem 1rem;
    flex-direction: column;
  }

  .channel-name {
    margin: 0 0 0.5rem 0;
  }

  .title_desc,
  .view_upload {
    padding: 0 1rem;
  }

  .view_upload {
    margin: 1rem 0;
  }

  .item .upload {
    width: 100%;
    text-align: left;
    margin: 0.4rem 0;
  }
  .item .views {
    margin: 0.4rem 0;
    order: 1;
  }
}
