@font-face {
  font-family: 'Roboto';
  src: url('../../fonts/roboto.eot');
  src: url('../../fonts/roboto.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/roboto.woff2') format('woff2'), url('../../fonts/roboto.woff') format('woff'),
  url('../../fonts/roboto.ttf') format('truetype'),
  url('../../fonts/roboto.svg#roboto') format('svg');
}

@font-face {
  font-family: 'Roboto-Medium';
  src: url('../../fonts/roboto-medium.eot');
  src: url('../../fonts/roboto-medium.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/roboto-medium.woff2') format('woff2'),
  url('../../fonts/roboto-medium.woff') format('woff'),
  url('../../fonts/roboto-medium.ttf') format('truetype'),
  url('../../fonts/roboto-medium.svg#roboto-medium') format('svg');
}


.notFound-wrapper {
  margin: 100px auto;
  display: flex;
  justify-content: space-between;
  align-self: center;
  align-items:center ;
  flex-direction: column;
}

.notFound-wrapper-text {
  display: flex;
  justify-content: center;
  align-self: center;
  align-items:center ;
  flex-direction: column;
}

.notFound-wrapper-text h1{
  font-family: Roboto-Medium;
  font-size: 34px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;
  letter-spacing: normal;
  text-align: left;
  color: #4d4a4a;
}

.notFound-wrapper-text p{
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.03px;
  text-align: center;
  color: #4d4a4a;
}

.notFound-wrapper-link {
  text-decoration: none;
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.03px;
  text-align: center;
  color: #4d4a4a;
  border-radius: 2px;
  background-color: #ffecac;
  padding: 14px 32px;
  margin: 50px 0;
}
