@font-face {
  font-family: 'Roboto';
  src: url('../../fonts/roboto.eot');
  src: url('../../fonts/roboto.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/roboto.woff2') format('woff2'), url('../../fonts/roboto.woff') format('woff'),
    url('../../fonts/roboto.ttf') format('truetype'),
    url('../../fonts/roboto.svg#roboto') format('svg');
}

@font-face {
  font-family: 'Roboto-Medium';
  src: url('../../fonts/roboto-medium.eot');
  src: url('../../fonts/roboto-medium.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/roboto-medium.woff2') format('woff2'),
    url('../../fonts/roboto-medium.woff') format('woff'),
    url('../../fonts/roboto-medium.ttf') format('truetype'),
    url('../../fonts/roboto-medium.svg#roboto-medium') format('svg');
}

.details-container {
  max-width: 1140px;
  margin: 0 auto;
}

.details-title {
  position: relative;
  display: flex;
  margin: 40px 0 50px 0;
  text-decoration: none;
}

.details-title-link {
  position: relative;
  display: flex;
  text-decoration: none;
}
.arrow-icon {
  padding: 6px 8px 0 0;
}

.details-title .text-style-1 {
  font-family: 'Roboto', sans-serif;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: #4d4a4a;
  margin: 0;
  z-index: 1;
}

.details-title .text-style-2 {
  font-family: 'Roboto', sans-serif;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: #373737;
}

.top-block {
  box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.06);
  background-color: #ffffff;
}

.most-popular-title {
  display: flex;
  position: relative;
  margin: 80px 0;
}

.text-style-3 {
  font-family: 'Roboto', sans-serif;
  font-size: 34px;
  font-weight: 600;
  text-align: left;
  color: #DAB84B;
  z-index: 1;
  margin: 0;
}

.video-box {
  padding: 24px 0;
  display: flex;
  flex-direction: row;
}

.details {
  padding: 12px 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.ch_title-category {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.ch_title-category .channel-name {
  width: 50%;
  color: #373737;
  font-family: 'Roboto-Medium', sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  padding: 0 24px;
  margin: 0;
  display: flex;
  align-items: center;
}

.ch_title-category .done-icon {
  width: 16px;
  height: 16px;
  padding: 0 0 0 8px;
}

.ch_title-category-category-block {
  border-radius: 2px;
  background-color: #f1f1f1;
  font-family: 'Roboto-Medium', sans-serif;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.02px;
  color: #373737;
  text-align: center;
  padding: 4px 10px;
}

.title-desc {
  padding: 0 24px;
}

.title-desc .title {
  margin: 32px 0 0 0;
  font-family: 'Roboto-Medium', sans-serif;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  text-align: left;
  color: #4d4a4a;
}

.title-desc .desc {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.16px;
  text-align: left;
  color: #757575;
}

.views {
  display: flex;
}

.views .views-icon {
  height: 16px;
  width: 16px;
  margin: 0;
  padding: 0 12px 0 0;
}

.tags-views-upload {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 24px;
}

.tags-views-upload h1 {
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: 0.02px;
  text-align: left;
  color: #4d4a4a;
}

.most-popular-video-block {
  display: flex;
  flex-wrap: wrap;
}

.most-popular-video-block > * {
  flex: 0 0 30%;
}

.most-popular-item {
  min-width: 360px;
  margin: 0 20px 32px 0;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.19);
  background-color: #ffffff;
  text-decoration: none;
}

.image-container {
  position: relative;
}

.image-container .duration-block {
  border-radius: 2px;
  border: #222222;
  background-color: #ffffff;
  position: absolute;
  bottom: 16px;
  right: 16px;
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.02px;
  color: #373737;
  text-align: center;
  padding: 2px 8px;
}

.img {
  overflow: hidden;
  max-height: 200px;
}
.img img {
  width: 100%;
  height: 100%;
}

.ch_name-tags {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding: 20px 24px 12px 24px;
}

.ch_name-tags .tags {
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: #5c5c5c;
  margin: 0;
}

.ch_name-tags .done-icon {
  width: 16px;
  height: 16px;
  padding: 0 0 0 8px;
}

.title .title {
  font-family: 'Roboto-Medium', sans-serif;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  color: #373737;
  margin: 0;
  padding: 0 0 12px 24px;
}

.player {
  width: 550px;
  height: 400px;
}

@media (max-width: 950px) {
  .details-title .text-style-1 {
    font-family: Roboto;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #4d4a4a;
  }

  .details-title .text-style-2 {
    font-family: Roboto;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #757575;
  }

  .details-title {
    margin: 2rem 0;
  }

  .block {
    top: 12px;
    width: 137px;
  }

  .top-block .details-container {
    width: 90%;

    display: flex;
    flex-direction: column;
  }

  .video-box {
    flex-direction: column;
    width: 100%;
  }
  .video-details-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .player {
    width: 100%;
    height: auto;
  }
  
  .tags-views-upload {
    flex-direction: column;
  }
  
  .tags-views-upload .views {
    order: 4
  }

  .details-container {
    width: 90%;
  }

  .most-popular-title {
    padding: 0 ;
    margin: 3rem 0;
  }

  .most-popular-item {
    margin: 1rem 0;
    min-width: 100%;
  }

  .ch_name-tags {
    flex-direction: column;
  }

  /* .ch_title-category .channel-name {
    padding-left: 0px;
  }

  .title-desc {
    padding: 0;
  }

  .tags-views-upload {
    padding: 0;
  } */
}
