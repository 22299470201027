.wrapper {
  min-height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.header {
}

.content {
  flex: 1 1 auto;
}

.footer {
}
