@font-face {
  font-family: 'Gilroy-Bold';
  src: url('../../fonts/gilroy-bold.eot');
  src: url('../../fonts/gilroy-bold.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/gilroy-bold.woff2') format('woff2'),
    url('../../fonts/gilroy-bold.woff') format('woff'),
    url('../../fonts/gilroy-bold.ttf') format('truetype'),
    url('../../fonts/gilroy-bold.svg#gilroy-bold') format('svg');
}

@font-face {
  font-family: 'Gilroy';
  src: url('../../fonts/gilroy-regular.eot');
  src: url('../../fonts/gilroy-regular.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/gilroy-regular.woff2') format('woff2'),
    url('../../fonts/gilroy-regular.woff') format('woff'),
    url('../../fonts/gilroy-regular.ttf') format('truetype'),
    url('../../fonts/gilroy-regular.svg#gilroy') format('svg');
}

.side-bar {
  position: fixed;
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: white;
  width: 250px;
  z-index: 6000;
  transform: translateX(-100%);
  transition: transform 0.5s ease-out;
}

.side-bar.open {
  transform: translateX(0);
}

.side-bar button {
  border: none;
  background: none;
  height: 24px;
  width: 24px;
  margin: 0;
  padding: 0;
}

.side-bar__close-button {
  margin: 30px 0 0 30px;
}

.side-bar ul {
  list-style: none;
  margin: 0 0 0 40px;
  padding: 0;
}

.side-bar ul li {
  padding: 0 0 14px 0;
}

.side-bar ul li:last-child {
  padding: 0;
}

.side-bar a {
  text-decoration: none;
}

.side-bar li#bold a {
  font-family: 'Gilroy-Bold';
  font-size: 16px;
  color: #222222;
}

.side-bar li#regular a {
  font-family: 'Gilroy';
  font-size: 14px;
  color: #222222;
}

/* .side-bar .trend {
  font-family: 'Gilroy-Bold';
  font-size: 18px;
}

.side-bar .trend hr {
  width: 100%;
  height: 1px;
  background-color: #f0f0f0;
  border: none;
}

.side-bar .trend hr:first-child {
  margin: 0 0 20px 0;
}

.side-bar .trend hr:last-child {
  margin: 20px 0 0 0;
}

.side-bar .trend a {
  color: #e9bd1f;
  margin: 0 0 0 40px;
} */

.side-bar .select-sidebar select {
  width: 126px;
  height: 34px;
  background-color: #ffffff;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'%3E%3Cpath fill='%23222' fill-rule='evenodd' d='M6 8.924l3.828 3.548c.323.3.82.305 1.15.013L15 8.924 13.833 8l-3.435 3.011L7.046 8 6 8.924z'/%3E%3C/svg%3E%0A");
  background-position: calc(100% - 0.4em) center;
  background-repeat: no-repeat;
  border: 1px solid #a6a6a6;
  border-radius: 6px;
  padding: 0 0 0 17px;
  font-family: 'Gilroy';
  font-size: 16px;
  color: #222222;
  margin: 0 0 40px 40px;
}

.side-bar__logo {
  margin: 45px 0 45px 30px;
  padding: 0;
}

.underline {
  border-top: 1px solid #D9D9D9;
  margin: 24px 40px;
}

.lenguage_select {
  margin-bottom: 8px;
  margin-left: 40px;
  font-family: 'Roboto';
  font-weight: 700;
  color: #757575;
  cursor: pointer;
}

.active_lenguage {
  color: #DAB84B;
}

.btn-link {
  margin-top: 15px;
  margin-left: 40px;
  cursor: pointer;
}
