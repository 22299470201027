@font-face {
  font-family: 'Gilroy-Bold';
  src: url('../../fonts/gilroy-bold.eot');
  src: url('../../fonts/gilroy-bold.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/gilroy-bold.woff2') format('woff2'),
    url('../../fonts/gilroy-bold.woff') format('woff'),
    url('../../fonts/gilroy-bold.ttf') format('truetype'),
    url('../../fonts/gilroy-bold.svg#gilroy-bold') format('svg');
}

@font-face {
  font-family: 'Gilroy';
  src: url('../../fonts/gilroy-regular.eot');
  src: url('../../fonts/gilroy-regular.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/gilroy-regular.woff2') format('woff2'),
    url('../../fonts/gilroy-regular.woff') format('woff'),
    url('../../fonts/gilroy-regular.ttf') format('truetype'),
    url('../../fonts/gilroy-regular.svg#gilroy') format('svg');
}

.footer-wrapper {
  width: 100%;
  height: 489px;
  background-color: #222222;
  display: flex;
  flex-direction: column;
}

.footer-wrapper .container {
  width: 100%;
  height: 489px;
  background-color: #222222;
  display: flex;
  flex-direction: column;
}

.subscribe__download_forms {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  height: 233px;
  color: #ffffff;
}

.footer-wrapper .container .subscribe-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  width: 50%;
}

.container .subscribe__download_forms .vertical-hr {
  align-self: center;
  border-right: 1px dashed #a6a6a6;
  height: 150px;
}

.error {
  margin: 0;
  color: red;
}

form {
  background: none;
  display: block;
  margin: 1rem 0;
}

@media only screen and (min-width: 48rem) {
  form {
    display: flex;
  }
}

.email_input {
  line-height: 3rem;
  padding: 0 2rem;
  width: 300px;
  box-sizing: border-box;
  background: none;
  color: white;
  font-family: Gilroy;
  font-size: 16px;
  border: 1px solid #a6a6a6;
  border-radius: 2px;
}

.email_input::placeholder {
  font-size: 16px;
  font-family: Gilroy;
  color: #fff;
}

.email_input:focus {
  outline: none;
}

button {
  color: #fff;
  border: none;
  padding: 0 1.5rem;
  margin-top: 1rem;
  width: 100%;
  background: #FFECAC;
  border: none;
  border-radius: 2px;
  font-family: Gilroy;
  font-size: 16px;
  color: #4D4A4A;
  cursor: pointer;
}

@media only screen and (min-width: 32rem) {
  button {
    width: auto;
  }
}

@media only screen and (min-width: 950px) {
  button {
    margin-left: 1rem;
    margin-top: 0;
  }
}

.footer-wrapper .container .download-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
}

.footer-wrapper .container .download-form .images img {
  margin: 32px 7px 0 7px;
  vertical-align: middle;
}

.footer-wrapper .container h1 {
  font-size: 26px;
  font-family: 'Gilroy';
  vertical-align: middle;
  margin: 0;
}

.footer-wrapper .container .hr {
  width: 100%;
  background-color: #a6a6a6;
  height: 1px;
  display: flex;
}

.footer-wrapper .container .row {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  height: 210px;
}

.footer-wrapper .container .column {
  width: 25%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 39px;
}

.column-link {
  text-decoration: none;
}

.footer-wrapper .container .row:after {
  display: table;
  clear: both;
}

.column h1 {
  font-family: 'Gilroy-Bold';
  font-size: 18px;
  color: white;
  padding: 0;
  margin: 0;
}

.column h2 {
  font-family: 'Gilroy';
  font-size: 14px;
  color: white;
  padding: 0;
  margin: 14px 0 0 0;
  text-decoration: none;
}

.column h2 img {
  margin: 0 6px 0 0;
}

.column .vertical-column {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.column .vertical-column a img {
  border: 1px solid #a6a6a6;
  border-radius: 6px;
  padding: 6px;
  width: 20px;
  height: 20px;
  margin: 15px 12px 0 0;
}

.column .vertical-column a img:hover {
  width: 25px;
  height: 25px;
  transition: 0.3s;
}

.copyrighting-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  margin-top: -80px;
}

.copyrighting-box .select {
  display: flex;
  justify-content: flex-start;
  float: left;
  width: 50%;
}

.copyrighting-box .copyrighting {
  display: flex;
  justify-content: flex-start;
  align-self: center;
  width: 25%;
  float: left;
}

.copyrighting-box .copyrighting h1 {
  font-family: Gilroy;
  font-size: 14px;
  color: #fff;
  margin-right: 80px;
}

.copyrighting-box .copyrighting img {
  margin-right: 6px;
  margin-left: 39px;
}

.copyrighting-box .select select {
  width: 126px;
  height: 34px;
  background-color: #222222;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'%3E%3Cpath fill='%23FFF' fill-rule='evenodd' d='M6 8.924l3.828 3.548c.323.3.82.305 1.15.013L15 8.924 13.833 8l-3.435 3.011L7.046 8 6 8.924z'/%3E%3C/svg%3E%0A");
  background-position: calc(100% - 0.4em) center;
  background-repeat: no-repeat;
  border: 1px solid #a6a6a6;
  border-radius: 2px;
  padding: 0 0 0 17px;
  font-family: Gilroy;
  font-size: 16px;
  color: white;
  margin-left: 80px;
}

.footer_form {
  padding: 0;
  margin: 0;
  margin-top: 32px;
}

@media (max-width: 950px) {
  .subscribe__download_forms {
    display: flex;
    flex-direction: column;
    height: auto;
    padding: 1rem 0;
  }

  .footer-wrapper .container .subscribe-form {
    padding: 20px 0;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
  }

  form {
    border: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
  }

 .email_input {
    border: 1px solid #a6a6a6;
    border-radius: 6px;
    line-height: 3rem;
    padding: 0 2rem;
    width: 100%;
  }

  button {
    padding: 1rem 1rem;
    width: 40%;
  }

  .container .subscribe__download_forms .vertical-hr {
    height: 1px;
    border-top: 1px dashed #a6a6a6;
    width: 70%;
  }

  .footer-wrapper .container .download-form {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
    padding: 1rem 0;
  }

  .footer-wrapper .container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .footer-wrapper .container .row {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100%;
    width: 100%;
    padding: 1rem 0;
  }
  
  .footer-wrapper .container .column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    align-items: center;
    padding: 1rem 0;
  }
  .footer-wrapper{ 
    height: auto;
  }
  .footer-wrapper .container{
    height: auto;
  }

  .copyrighting-box {
    margin-top: 0;
    justify-content: center;
  }

  .copyrighting-box .copyrighting {
    padding: 1rem 0;  
    width: 50%;
  }

  .copyrighting-box .select {
    display: none;
  }

  .subscribe__download_forms h1 {
    text-align: center;
  }

  .copyrighting-box .copyrighting h1 {
    font-family: Gilroy;
    font-size: 14px;
    color: #fff;
    margin-right:2rem;
  }
}