@font-face {
  font-family: 'Roboto';
  src: url('../../fonts/roboto.eot');
  src: url('../../fonts/roboto.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/roboto.woff2') format('woff2'), url('../../fonts/roboto.woff') format('woff'),
    url('../../fonts/roboto.ttf') format('truetype'),
    url('../../fonts/roboto.svg#roboto') format('svg');
}

@font-face {
  font-family: 'Roboto-Medium';
  src: url('../../fonts/roboto-medium.eot');
  src: url('../../fonts/roboto-medium.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/roboto-medium.woff2') format('woff2'),
    url('../../fonts/roboto-medium.woff') format('woff'),
    url('../../fonts/roboto-medium.ttf') format('truetype'),
    url('../../fonts/roboto-medium.svg#roboto-medium') format('svg');
}

.about-wrapper {
  max-width: 1120px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 70px 0;
}

.about-page-title {
  font-family: 'Roboto', sans-serif;
  font-size: 42px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;
  letter-spacing: normal;
  text-align: left;
  color: #4d4a4a;
  text-align: center;
}

.yellow-block {
  color: #DAB84B;
}

.first-block {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin: 50px 0;
}

.first-block .photo {
  width: 50%;
  object-fit: contain;
}

.first-block .text {
  margin: 100px 0 0 0;
}

.first-block .text h1 {
  width: 550px;
  height: 78px;
  font-family: 'Roboto', sans-serif;
  font-size: 68px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.15;
  letter-spacing: normal;
  text-align: left;
  color: #4d4a4a;
  margin: 0;
}

.first-block .text p {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.03px;
  text-align: left;
  color: #757575;
}

.second-block {
  display: flex;
  flex-direction: row;
}

.second-block .vertical-line {
  width: 3px;
  height: 120px;
  background-color: #ffecac;
}

.second-block .info-block {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 0 0 40px;
  padding-top: 20px;
}

.second-block .info-block .text p {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  font-size: 34px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;
  letter-spacing: normal;
  text-align: left;
  color: #4d4a4a;
}

.second-block .info-block .info {
  display: flex;
  flex-direction: row;
}

.second-block .info-block .info .info-item {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.second-block .info-block .info .avatars {
  margin: 0 24px;
  align-self: center;
}

.second-block .info-block .info .info-item h1 {
  height: 40px;
  font-family: Roboto;
  font-size: 34px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;
  letter-spacing: normal;
  text-align: left;
  color: #4d4a4a;
  margin: 0 14px 0 0;
}

.second-block .info-block .info .info-item p {
  width: 68px;
  height: 40px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: 0.02px;
  text-align: left;
  color: #757575;
  margin: 0;
}

.second-block .info-block .info .vertical-ln {
  width: 1px;
  height: 40px;
  background-color: #c4c4c4;
  margin: 0 24px;
}

.third-block {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 100px 0;
}

.third-block .blog-item {
  display: flex;
  flex-direction: column;
  padding: 40px;
  background: #FCFCFC;
  box-shadow: 0px 6px 12px rgba(197, 197, 197, 0.2);
  max-width: calc(50% - 90px);
}

@media (max-width: 950px) {
  .third-block .blog-item {
    max-width: 100%;
  }
}

.third-block .blog-item .blog-text h1 {
  font-family: 'Roboto-Medium';
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: normal;
  text-align: left;
  color: #4d4a4a;
}

.third-block .blog-item .blog-text p {
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.03px;
  text-align: left;
  color: #757575;
}

.fourth-block {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-shadow: 0 0 80px 0 rgba(0, 0, 0, 0.1);
  position: relative;
}

.fourth-block .text {
  width: 50%;
  margin: 40px;
}

.fourth-block .text h1 {
  font-family: 'Roboto-Medium';
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: normal;
  text-align: left;
  color: #4d4a4a;
}

.fourth-block .text p {
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.03px;
  text-align: left;
  color: #757575;
}

.fourth-block .fourth-block-img-first {
  height: 100%;
  object-fit: contain;
  display: flex;
  display: none;
}

.fourth-block .fourth-block-img-first img {
  height: 100%;
  width: 100%;
}

.fourth-block .fourth-block-img-second {
  height: 100%;
  object-fit: contain;
  display: flex;
}

.fourth-block .fourth-block-img-second img {
  height: 100%;
  width: 100%;
}

.fourth-block .star {
  position: absolute;
  top: -15px;
  left: -15px;
}

.fifth-block {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 95px 0;
  box-shadow: 0 0 80px 0 rgba(0, 0, 0, 0.1);
  position: relative;
}

.fifth-block .star {
  position: absolute;
  top: -15px;
  right: -15px;
}

.fifth-block .text {
  margin: 40px 40px 40px 0;
  width: 40vw;
}

.fifth-block .text h1 {
  font-family: 'Roboto-Medium';
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: normal;
  text-align: left;
  color: #4d4a4a;
  margin: 0 0 16px 0;
}

.fifth-block .text p {
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.03px;
  text-align: left;
  color: #757575;
  margin: 16px 0 0 0;
}

.fifth-block .fifth-block-img {
  width: auto;
  height: 100%;
  display: flex;
}

.fifth-block .fifth-block-img img {
  height: 100%;
  width: 100%;
}

.footer-block {
  display: flex;
  position: relative;
  width: 100%;
}

.footer-block .shadow {
  position: absolute;
  right: 0;
  top: 40px;
  width: 622.8px;
  height: 736.2px;
  opacity: 0.7;
  -webkit-filter: blur(70px);
  filter: blur(70px);
}

.footer-block .footer-container {
  padding: 120px 80px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  overflow-x: scroll;
  margin: 0 auto;
  z-index: 2;
}

.footer-block .footer-container::-webkit-scrollbar {
  height: 5px;
  background-color: #323232;
}

.footer-block .footer-container::-webkit-scrollbar-thumb {
  background: #C4C4C4;
  border-radius: 2px;
}

.footer-block .footer-container .footer-container-text {
  display: flex;
  flex-direction: column;
}

.footer-block .footer-container .footer-container-text h1 {
  width: 455px;
  font-family: 'Roboto-Medium';
  font-size: 40px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: 0.06px;
  text-align: left;
  color: #4D4A4A;
}

.footer-block .footer-container .footer-container-text p {
  display: flex;
  width: 455px;
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.03px;
  text-align: left;
  color: #4D4A4A;
}

.footer-images {
  display: flex;
  margin: 0 80px;
}

.footer-phone-img {
  display: flex;
  margin: 0 20px;
}

.footer-phone-img img {
  max-width: 204px;
}

@media (max-width: 950px) {
  .about-wrapper {
    padding: 1rem 0;
  }

  .about-page-title {
    margin: 0 2rem;
  }

  .first-block {
    justify-content: center;
  }

  .first-block .photo {
    display: none;
  }

  .first-block .text {
    width: 80%;
    margin: 0;
  }

  .first-block .text h1 {
    width: auto;
    height: auto;
    margin: 0;
    font-family: 'Roboto-Medium';
    font-size: 38px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.53;
    letter-spacing: normal;
    text-align: left;
    color: #4d4a4a;
  }

  .second-block {
    display: flex;
    justify-content: center;
  }

  .second-block .vertical-line {
    display: none;
  }

  .second-block .info-block {
    width: 80%;
    margin: 0;
  }

  .second-block .info-block .info {
    display: flex;
    justify-content: space-between;
  }

  .second-block .info-block .text p {
    margin: 0 0 2rem 0;
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: normal;
    text-align: left;
    color: #4d4a4a;
  }

  .second-block .info-block .info .info-item h1 {
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: normal;
    text-align: left;
    color: #4d4a4a;
    margin: 0 0.8rem 0 0;
  }

  .second-block .info-block .info .vertical-ln {
    margin: 0 0.5rem;
  }

  .second-block .info-block .info .avatars {
    display: none;
  }

  .third-block {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 2.5rem 0;
  }
  .third-block .blog-item {
    padding: 0;
    width: 80%;
  }

  .blog-img {
    display: flex;
    justify-content: center;
    margin: 1rem 0;
  }

  .fourth-block {
    align-self: center;
    flex-direction: column;
    width: 80%;
    justify-content: center;
    align-items: center;
  }

  .fourth-block .fourth-block-img-second {
    display: none;
  }

  .fourth-block .fourth-block-img-first {
    display: flex;
  }

  .fourth-block .text {
    width: 80%;
    margin: 1rem 0;
  }

  .fifth-block {
    align-self: center;
    flex-direction: column;
    width: 80%;
    justify-content: center;
    align-items: center;
    margin: 3rem 0;
  }

  .fifth-block .text {
    width: 80%;
    margin: 1rem 0;
  }

  .footer-block .footer-container {
    flex-direction: column;
    padding: 3rem 2rem;
  }
  .footer-images {margin: 0;}

  .footer-block .shadow{
    width: 350.8px;
    height: 736.2px;
  }

  .footer-block .footer-container .footer-container-text h1 {
    font-size: 20px;
    width: 100%;
  }

  .footer-block .footer-container .footer-container-text p {
    font-size: 16px;
    width: 100%;
  }
}
