.wrapper-pop-up {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background: rgba(34, 34, 34, 0.5);
  z-index: 10000;
}

.layout {
  width: auto;
  height: auto;
  padding: 60px 50px 30px 50px;
  background-color: #fff;
  width: 685px;
  margin: 200px auto;
  font-family: 'Roboto', sans-serif;;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.close {
  position: absolute;
  top: 40px;
  right: 40px;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.title {
  font-size: 42px;
  font-weight: 600;
  color: #4D4A4A;
  text-align: center;
  margin: 0 0 10px 0;
}

.yealow {
  color: #DAB84B;
}

.content-dialog {
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 20px;
}

.action {
  outline: none;
  width: 280px;
  padding: 14px 40px;
  background: #DAB84B;
  border-radius: 2px;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  margin: auto;
}

.content_list-pop_up {
  text-align: left;
  list-style-type: none;
  padding-left: 0;
}

.content_list-pop_up li {
  margin-bottom: 10px;
  display: flex;
  align-items: flex-start;
}

.list_point {
  min-width: 8px;
  height: 8px;
  background-color: #FFECAC;
  border-radius: 100%;
  margin-right: 12px;
  margin-top: 5px;
}

.bottom_text-pop_up {
  text-align: center;
  font-weight: 600;
  margin-bottom: 32px;
  margin-top: 6px;
}

.yellow {
  color: #DAB84B;
}

@media (max-width: 950px) {
  .layout {
    width: calc(80% - 80px);
    margin: 30px auto;
    padding: 30px 40px 20px 40px;
  }

  .title {
    font-size: 24px;
  }

  .close {
    top: 20px;
    right: 20px;
  }

  .content {
    font-size: 14px;
  }

  .action {
    width: 100%;
  }
}

