@font-face {
  font-family: 'Gilroy-Bold';
  src: url('../../fonts/gilroy-bold.eot');
  src: url('../../fonts/gilroy-bold.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/gilroy-bold.woff2') format('woff2'),
    url('../../fonts/gilroy-bold.woff') format('woff'),
    url('../../fonts/gilroy-bold.ttf') format('truetype'),
    url('../../fonts/gilroy-bold.svg#gilroy-bold') format('svg');
}

header {
  background-color: #e0b72c;
  color: #ffffff;
  display: flex;
  z-index: 1;
  width: 100%;
  height: 70px;
  top: 0;
  left: 0;
}

.container {
  width: 100%;
  padding: 0;
  margin: 0;
}

.items {
  height: 70px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
}

.items__logo {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.logo {
  display: flex;
  margin: 0 30px;
}

.menu-button {
  display: flex;
  padding: 0;
  background: none;
  border: none;
}

.download-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 126px;
  height: 44px;
  background-color: #e0b72c;
  color: white;
  border: none;
  border-radius: 6px;
  font-family: 'Gilroy-Bold';
  font-size: 16px;
  margin: 0;
  padding: 0 10px;
  text-decoration: none;
}

@media (max-width: 950px) { 
  .download-button {
    visibility: hidden;
  }

  .menu-button {
    margin: 0;
  }
}

