@font-face {
  font-family: 'Roboto';
  src: url('../../fonts/roboto.eot');
  src: url('../../fonts/roboto.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/roboto.woff2') format('woff2'), url('../../fonts/roboto.woff') format('woff'),
    url('../../fonts/roboto.ttf') format('truetype'),
    url('../../fonts/roboto.svg#roboto') format('svg');
}

@font-face {
  font-family: 'Roboto-Medium';
  src: url('../../fonts/roboto-medium.eot');
  src: url('../../fonts/roboto-medium.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/roboto-medium.woff2') format('woff2'),
    url('../../fonts/roboto-medium.woff') format('woff'),
    url('../../fonts/roboto-medium.ttf') format('truetype'),
    url('../../fonts/roboto-medium.svg#roboto-medium') format('svg');
}

.contacts-wrapper {
  position: relative;
}

.background-img {
  position: relative;
  z-index: -2;
}

.background-img .Background-image {
  width: 100%;
  object-fit: contain;
  position: absolute;
}

.contacts-container {
  max-width: 1120px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding: 70px 0;
}

.text-block h1 {
  font-family: 'Roboto', sans-serif;
  font-size: 34px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
}

.information {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  margin: 0 0 0 0;
}

.information h2 {
  font-family: 'Roboto-Medium', sans-serif;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.03px;
  text-align: left;
  color: #ffffff;
}

.information h3 {
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  line-height: 1.5;
  letter-spacing: 0.03px;
  text-align: left;
  color: #ffffff;
}

.feedback-form {
  padding: 60px 40px;
  background: white;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  max-width: 455px;
}

.feedback-form h1 {
  font-family: 'Roboto', sans-serif;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: #4d4a4a;
}

.send-button {
  padding: 14px 0;
  margin: 32px 0 0 0;
  border: none;
  border-radius: 2px;
  background-color: #ffecac;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.03px;
  text-align: center;
  color: #4d4a4a;
}

.contact-form-input {
  width: 375px;
}

.h1-1 {
  display: none;
}

@media (max-width: 950px) {
  .Background-image {
    display: none;
  }

  .contacts-container {
    flex-direction: column;
    width: 90%;
    padding: 1rem 0;
  }

  .text-block h1,
  .information h2,
  .information h3 {
    color: black;
  }

  .information {
    margin: 1rem 0 0 0;
  }

  .feedback-form {
    padding: 0;
  }

  .feedback-form h1 {
    font-family: "Roboto-Medium";
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: 0.03px;
    text-align: left;
    color: #4d4a4a;
  }

  .contact-form-input {
    width: 100%;
  }

  .feedback-form .send-button {
    align-self: center;
  }

  .contact-form-message {
    width: 100%;
  }

  .text-block {
    order: 1;
  }

  .h1-1 {
    display: flex;
    font-family: Roboto;
    font-size: 24px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #DAB84B;
  }

  .h1-2 {
    display: none;
  }
  
  .information {
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.19);
  background-color: #ffffff;
  padding: 1.5rem;
  margin: 2rem 0;
  }

  .information-item {
    margin: 2.5rem 0
  }

  .information-item:first-child, .information-item:last-child {
    margin: 0;
  }
}

.yealow_text {
  color: #DAB84B !important;
}
